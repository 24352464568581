.container-content.with-side-menu-layout {
    display: flex;
    flex-direction: row;
    @media (max-width:768px) {
        flex-direction: column;
    }
    .content-intro {
        flex: 1 1 80%;
    }
}

.side-menu {
    flex: 1 0 200px;
    margin: 0 24px 0 80px;
    @media (max-width:768px) {
        margin-left: 0px;
    }
    &-title {
        font-family: Libre Franklin;
        font-style: normal;
        font-weight: normal;
        font-size: 28px;
        line-height: 34px;
        letter-spacing: -0.02em;
        color: #272727;
        margin-bottom: 24px;

        &:before {
            content: '';
            display: inline-block;
            background: linear-gradient(142.7deg, #0C479D 4.3%, #006CD3 144.63%);
            width: 8px;
            height: 26px;
            margin-right: 14px;
            border-top-right-radius: 24px;
            border-bottom-left-radius: 24px;
        }
    }

    &-list {
        &-item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px dashed #C4C4C4;
            a {
                flex-grow: 1;
                display: block;
                font-family: Libre Franklin;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 27px;
                padding: 14px 20px 14px 0;
                color: #2F2F2F;
                transition: color 0.3s ease, transform 0.3s ease;
                white-space: nowrap;
                &.active {
                    font-weight: 600;
                    color: @cs-color-primary;
                }
            }
            &:after{
                opacity: 0;
                display: inline-block;
                content: "\f061";
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
                font-size: 14px;
                color: @cs-color-secondary;
                transition: opacity 0.3s ease, transform 0.3s ease;
                transform: translateX(-24px);
            }
            &:hover{
                & a{
                    color: @cs-color-secondary;
                    transform: translateX(4px);
                }
                &:after{
                    opacity: 1;
                    transform: translateX(0px);
                }
            }
        }
    }
}