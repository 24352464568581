
.content-wrapper .container-content.lg{
    max-width: 1440px;
}
.content-wrapper{
    min-height: 600px;
    font-size: 16px;
    line-height: 26px;
    position:relative;
    z-index: 0;
    /*-----------
    Banner style
    ------------*/
    .content-banner{
        width: 100%;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 34.25%, #FFFFFF 82.89%), #EAF5FF;
        position: relative;   
        min-height: 240px;
        height: 600px;
        margin-bottom: 75px;
        margin-top: 64px; 
        overflow: hidden;  
        &::before{
            content: ' ';
            position: absolute;
            z-index: 1;
            width: 229px;
            height: 316px;
            left: 0;
            top: 323px; 
            background-image: url(../img/solutions/bg-img-dot.png);
            background-position: right center;
            background-size: cover;
            background-repeat: no-repeat;     
            @media (max-width:768px) {
                display: none;
            }
        }     
        &-image{
            position: absolute;
            height: 100%;
            background-position: right bottom;
            background-size: cover;
            background-repeat: no-repeat;
            width: 100%;
            right: -150px;
            @media (min-width:480px) {
                background-size: cover;
                width: 100%;
                right: -150px;
            }
            @media (min-width:768px) {
                background-size: cover;
                width: 75%;
                right: -20px;
            }
            @media (min-width:1024px) {
                width: 70%;
                right: 0;
            }
            @media (min-width:1280px) {
                width: 65%;
                right: 0;
            }
            @media (min-width:1440px) {
                width: 50%;
                right: 0;
            }
        }
        &-title{
            position: absolute;
            height: 100%;
            width: 100%;
            text-transform: uppercase;
            display: flex;
            flex-flow: column nowrap;
            justify-content:center;
            align-items: flex-start; 
            padding-left: 16px;
            background:linear-gradient(89.73deg, #E5EFFF 61.44%, rgba(229, 239, 255, 0) 101.79%);
            @media (min-width:480px) {
                padding-left: 16px;
                background:radial-gradient(52.6% 47.93% at 27.64% 41.72%, #E5EFFF 0%, rgba(229, 239, 255, 0) 100%), radial-gradient(80.4% 74.45% at 18.43% 18.93%, #E5EFFF 0%, rgba(229, 239, 255, 0) 100%);
            }
            @media (min-width:768px) {
                padding-left: 24px;
            }
            @media (min-width:1024px) {
                padding-left: 20%;
            }
            @media (min-width:1280px) {
                background: none;
                padding-left: 20%;
            }
            .banner-title-back{
                width: 100%;
            }
            .banner-title-front{            
                font-family: 'Raleway-Bold';
                font-style: normal;
                font-weight: 800;
                letter-spacing: 0.05em;
                color: @cs-color-primary;
                font-size: 24px;
                line-height: 54px;
                margin-bottom: 8px;
                @media (min-width:768px) {
                    font-size: 52px;
                    line-height: 61px;
                    margin-bottom: 28px;
                }
            }
            .banner-title-desc{
                font-family: 'Raleway';
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 1.5;
                letter-spacing: 0.05em;
                text-transform: capitalize;
                color: @cs-color-primary;
                margin-top: 0px;
                width: 100%;
                @media (min-width:480px) {
                    margin-top: 0px;
                    font-size: 14px;
                    width: 100%;
                }
                @media (min-width:768px) {
                    margin-top: 0px;
                    font-size: 16px;
                    width: 100%;
                }
                @media (min-width:1024px) {
                    margin-top: 18px;
                    font-size: 18px;
                    width: 70%;
                }
                @media (min-width:1280px) {
                    margin-top: 18px;
                    font-size: 18px;
                    width: 50%;
                }
            }
            .bread-crumb{
                font-family: 'Raleway', 'Libre Franklin', 'Lato', sans-serif;
                color:@cs-color-primary;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 21px;
                letter-spacing: 0.05em;
                text-transform: capitalize;
                
            }
        } 

        /*--- light version---*/
        &.darken{
            .banner-title-desc{
                color: #fff;
            }
            .banner-title-front{
                color: #fff;
                text-shadow: 0px 1px 3px rgba(46, 51, 56, 0.2), 0px 1px 4px rgba(46, 51, 56, 0.12), 0px 3px 5px rgba(23, 26, 28, 0.09);
            }
            .bread-crumb{
                color:#fff;
                a{
                    color:#fff;
                    &:hover,
                    &:focus{
                        color:rgba(255, 255, 255, 0.8);
                    }
                }
            }
        }
        /*--- size variant---*/
        &.md{        
            height: 600px; 
            @media (max-width:768px) {
                height: 240px;
            }
        } 
        &.sm{   
            height: 400px; 
            @media (max-width:768px) {
                height: 240px;
            }
        }
    }
}   
.content-banner-title.light{
    .banner-title-front{  
        color:#fff;
    }
    .bread-crumb{
        color:#fff;
        a{
            color:#fff;
            &:hover,
            &:focus{
                color:rgba(255, 255, 255, 0.8);
            }
        }
    }
}      

// svg text effects
.gradient-bg{
    background-image: url(../img/gradient-bg.svg);
    background-size: contain;
    background-repeat: repeat;
    background-position: 0 0;
}
.outline-svg-text{
    width: 100%;
    margin-bottom: -32px;
    &.large{
        height: 160px;
        .svg-text{
            font-size: 64px;  
            @media (min-width:480px) {
                font-size: 64px;  
            }
            @media (min-width:768px) {
                font-size: 72px;  
            }
            @media (min-width:1024px) {
                font-size: 150px;  
            }
        }
    }
    &.middle{
        height: 110px;
        .svg-text{
            font-size: 64px;  
            @media (min-width:480px) {
                font-size: 72px;  
            }
            @media (min-width:768px) {
                font-size: 72px;  
            }
            @media (min-width:1024px) {
                font-size: 100px;  
            }
        }
    } 
}
.svg-text{
    font-family: Raleway-Bold; 
    fill: none;
    stroke-width: 1;
}

.gap-merge{
    padding-bottom: 200px;
}

.content-section{
    padding: 40px;
    background: #F8FBFF;
    &>div:first-child{
        margin-top: 0 !important;
    }
}
