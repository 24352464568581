.icon-48 {
  width: 48px;
  height: 48px;
}
.news-gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.news-gallery-item {
  flex: 0 0 33.3333%;
  padding: 18px;
}
@media (max-width: 1024px) {
  .news-gallery-item {
    flex-basis: 50%;
  }
}
@media (max-width: 768px) {
  .news-gallery-item {
    flex-basis: 100%;
  }
}
.news-gallery-item-wrapper {
  height: 560px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 12px 48px 0px rgba(23, 26, 28, 0.03), 0px 9px 28px 0px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08);
}
@media (max-width: 1024px) {
  .news-gallery-item-wrapper {
    height: 520px;
  }
}
@media (max-width: 768px) {
  .news-gallery-item-wrapper {
    height: 480px;
  }
}
.news-gallery-item-content {
  flex-grow: 1;
  padding: 40px 40px 24px 40px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.news-gallery-item-content-title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #0c479d;
  margin-top: 16px;
}
.news-gallery-item-content-title:hover {
  cursor: pointer;
}
.news-gallery-item-content-date {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.75);
}
.news-gallery-item-content-detail {
  margin-top: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}
.news-gallery-item-content-detail > p {
  line-height: 24px;
  margin-bottom: 0;
}
.news-gallery-item-content-detail:after {
  display: block;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  content: '';
  height: 48px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 60%);
}
.news-gallery-item-img {
  flex-basis: 280px;
  flex-grow: 0;
  flex-shrink: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.footer .footer-wrapper {
  margin: 16px auto;
  max-width: 1280px;
}
@media (max-width: 1200px) {
  .footer .footer-wrapper {
    flex-direction: column;
    white-space: normal;
    word-break: normal;
    text-align: center;
    line-height: 24px;
    margin-top: 16px;
  }
  .footer .footer-wrapper > .footer-left > .copy-right,
  .footer .footer-wrapper > .footer-left > .footer-menu {
    margin-bottom: 16px;
  }
}
@media (max-width: 768px) {
  .industry-awards .contact-us-panel > .sub-title {
    font-size: 16px;
  }
  .industry-awards .contact-us-panel > .title {
    font-size: 24px;
  }
}
.content-wrapper .container-content.lg {
  max-width: 1440px;
}
.content-wrapper {
  min-height: 600px;
  font-size: 16px;
  line-height: 26px;
  position: relative;
  z-index: 0;
  /*-----------
    Banner style
    ------------*/
}
.content-wrapper .content-banner {
  width: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 34.25%, #FFFFFF 82.89%), #EAF5FF;
  position: relative;
  min-height: 240px;
  height: 600px;
  margin-bottom: 75px;
  margin-top: 64px;
  overflow: hidden;
  /*--- light version---*/
  /*--- size variant---*/
}
.content-wrapper .content-banner::before {
  content: ' ';
  position: absolute;
  z-index: 1;
  width: 229px;
  height: 316px;
  left: 0;
  top: 323px;
  background-image: url(../img/solutions/bg-img-dot.png);
  background-position: right center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 768px) {
  .content-wrapper .content-banner::before {
    display: none;
  }
}
.content-wrapper .content-banner-image {
  position: absolute;
  height: 100%;
  background-position: right bottom;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  right: -150px;
}
@media (min-width: 480px) {
  .content-wrapper .content-banner-image {
    background-size: cover;
    width: 100%;
    right: -150px;
  }
}
@media (min-width: 768px) {
  .content-wrapper .content-banner-image {
    background-size: cover;
    width: 75%;
    right: -20px;
  }
}
@media (min-width: 1024px) {
  .content-wrapper .content-banner-image {
    width: 70%;
    right: 0;
  }
}
@media (min-width: 1280px) {
  .content-wrapper .content-banner-image {
    width: 65%;
    right: 0;
  }
}
@media (min-width: 1440px) {
  .content-wrapper .content-banner-image {
    width: 50%;
    right: 0;
  }
}
.content-wrapper .content-banner-title {
  position: absolute;
  height: 100%;
  width: 100%;
  text-transform: uppercase;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  padding-left: 16px;
  background: linear-gradient(89.73deg, #E5EFFF 61.44%, rgba(229, 239, 255, 0) 101.79%);
}
@media (min-width: 480px) {
  .content-wrapper .content-banner-title {
    padding-left: 16px;
    background: radial-gradient(52.6% 47.93% at 27.64% 41.72%, #E5EFFF 0%, rgba(229, 239, 255, 0) 100%), radial-gradient(80.4% 74.45% at 18.43% 18.93%, #E5EFFF 0%, rgba(229, 239, 255, 0) 100%);
  }
}
@media (min-width: 768px) {
  .content-wrapper .content-banner-title {
    padding-left: 24px;
  }
}
@media (min-width: 1024px) {
  .content-wrapper .content-banner-title {
    padding-left: 20%;
  }
}
@media (min-width: 1280px) {
  .content-wrapper .content-banner-title {
    background: none;
    padding-left: 20%;
  }
}
.content-wrapper .content-banner-title .banner-title-back {
  width: 100%;
}
.content-wrapper .content-banner-title .banner-title-front {
  font-family: 'Raleway-Bold';
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.05em;
  color: #0c479d;
  font-size: 24px;
  line-height: 54px;
  margin-bottom: 8px;
}
@media (min-width: 768px) {
  .content-wrapper .content-banner-title .banner-title-front {
    font-size: 52px;
    line-height: 61px;
    margin-bottom: 28px;
  }
}
.content-wrapper .content-banner-title .banner-title-desc {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: #0c479d;
  margin-top: 0px;
  width: 100%;
}
@media (min-width: 480px) {
  .content-wrapper .content-banner-title .banner-title-desc {
    margin-top: 0px;
    font-size: 14px;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .content-wrapper .content-banner-title .banner-title-desc {
    margin-top: 0px;
    font-size: 16px;
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .content-wrapper .content-banner-title .banner-title-desc {
    margin-top: 18px;
    font-size: 18px;
    width: 70%;
  }
}
@media (min-width: 1280px) {
  .content-wrapper .content-banner-title .banner-title-desc {
    margin-top: 18px;
    font-size: 18px;
    width: 50%;
  }
}
.content-wrapper .content-banner-title .bread-crumb {
  font-family: 'Raleway', 'Libre Franklin', 'Lato', sans-serif;
  color: #0c479d;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
}
.content-wrapper .content-banner.darken .banner-title-desc {
  color: #fff;
}
.content-wrapper .content-banner.darken .banner-title-front {
  color: #fff;
  text-shadow: 0px 1px 3px rgba(46, 51, 56, 0.2), 0px 1px 4px rgba(46, 51, 56, 0.12), 0px 3px 5px rgba(23, 26, 28, 0.09);
}
.content-wrapper .content-banner.darken .bread-crumb {
  color: #fff;
}
.content-wrapper .content-banner.darken .bread-crumb a {
  color: #fff;
}
.content-wrapper .content-banner.darken .bread-crumb a:hover,
.content-wrapper .content-banner.darken .bread-crumb a:focus {
  color: rgba(255, 255, 255, 0.8);
}
.content-wrapper .content-banner.md {
  height: 600px;
}
@media (max-width: 768px) {
  .content-wrapper .content-banner.md {
    height: 240px;
  }
}
.content-wrapper .content-banner.sm {
  height: 400px;
}
@media (max-width: 768px) {
  .content-wrapper .content-banner.sm {
    height: 240px;
  }
}
.content-banner-title.light .banner-title-front {
  color: #fff;
}
.content-banner-title.light .bread-crumb {
  color: #fff;
}
.content-banner-title.light .bread-crumb a {
  color: #fff;
}
.content-banner-title.light .bread-crumb a:hover,
.content-banner-title.light .bread-crumb a:focus {
  color: rgba(255, 255, 255, 0.8);
}
.gradient-bg {
  background-image: url(../img/gradient-bg.svg);
  background-size: contain;
  background-repeat: repeat;
  background-position: 0 0;
}
.outline-svg-text {
  width: 100%;
  margin-bottom: -32px;
}
.outline-svg-text.large {
  height: 160px;
}
.outline-svg-text.large .svg-text {
  font-size: 64px;
}
@media (min-width: 480px) {
  .outline-svg-text.large .svg-text {
    font-size: 64px;
  }
}
@media (min-width: 768px) {
  .outline-svg-text.large .svg-text {
    font-size: 72px;
  }
}
@media (min-width: 1024px) {
  .outline-svg-text.large .svg-text {
    font-size: 150px;
  }
}
.outline-svg-text.middle {
  height: 110px;
}
.outline-svg-text.middle .svg-text {
  font-size: 64px;
}
@media (min-width: 480px) {
  .outline-svg-text.middle .svg-text {
    font-size: 72px;
  }
}
@media (min-width: 768px) {
  .outline-svg-text.middle .svg-text {
    font-size: 72px;
  }
}
@media (min-width: 1024px) {
  .outline-svg-text.middle .svg-text {
    font-size: 100px;
  }
}
.svg-text {
  font-family: Raleway-Bold;
  fill: none;
  stroke-width: 1;
}
.gap-merge {
  padding-bottom: 200px;
}
.content-section {
  padding: 40px;
  background: #F8FBFF;
}
.content-section > div:first-child {
  margin-top: 0 !important;
}
.content-wrapper .container-content a.btn-collapse-title {
  display: block;
  position: relative;
  color: #00479d;
  padding: 24px 40px;
  margin-bottom: 0;
  font-size: 20px;
  line-height: 24px;
  background-color: #f0f6ff;
  border-bottom: 1px solid #e6e6e6;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
}
.content-wrapper .container-content a.btn-collapse-title:hover {
  color: #00479d;
  background-color: #f0f6ff;
}
.content-wrapper .container-content a.btn-collapse-title:active {
  color: #00479d;
  background-color: #f0f6ff;
}
.content-wrapper .container-content a.btn-collapse-title::before {
  content: ' ';
  position: absolute;
  left: 16px;
  top: 32px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 3px solid #00479d;
}
.content-wrapper .container-content a.btn-collapse-title span.icon {
  position: absolute;
  display: block;
  width: 14px;
  height: 14px;
  right: 16px;
  top: 30px;
  color: #00479d;
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  /* IE 9 */
  -moz-transform: rotate(90deg);
  /* Firefox */
  -webkit-transform: rotate(90deg);
  /* Safari 和 Chrome */
  -o-transform: rotate(90deg);
  /* Opera */
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
}
.content-wrapper .container-content a.btn-collapse-title.collapsed {
  color: #272727;
  background-color: transparent;
}
.content-wrapper .container-content a.btn-collapse-title.collapsed:hover {
  color: #00479d;
  background-color: #f0f6ff;
}
.content-wrapper .container-content a.btn-collapse-title.collapsed:hover span.icon {
  color: #00479d;
}
.content-wrapper .container-content a.btn-collapse-title.collapsed:active {
  color: #00479d;
  background-color: #f0f6ff;
}
.content-wrapper .container-content a.btn-collapse-title.collapsed span.icon {
  color: #a6a6a6;
  transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -moz-transform: rotate(0deg);
  /* Firefox */
  -webkit-transform: rotate(0deg);
  /* Safari 和 Chrome */
  -o-transform: rotate(0deg);
  /* Opera */
}
.content-wrapper .container-content .faq-collapse-panel {
  padding: 20px;
  background: #F8FBFF;
}
.content-wrapper .container-content .faq-collapse-panel table tr th {
  vertical-align: middle;
  background-color: #F2F2F2;
  padding: 12px 20px;
}
.content-wrapper .container-content .faq-collapse-panel table tr td {
  background-color: #fff;
  padding: 12px 20px;
}
.carrier-block {
  display: -webkit-flex;
  /* Safari */
  display: flex;
  min-height: 200px;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  /* flex  */
  padding: 60px 0;
  border-bottom: 1px solid #e6e6e6;
}
.carrier-block .carrier-logo {
  flex: 1 0 300px;
  margin-right: 48px;
}
.carrier-block .carrier-logo img.logo {
  display: block;
  width: 232px;
  height: auto;
  margin: auto;
}
.carrier-block .carrier-information {
  flex: 1 1 100%;
}
.carrier-block .carrier-information .carrier-name {
  font-family: Libre Franklin;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #0C479D;
  padding-bottom: 24px;
}
@media screen and (max-width: 992px) {
  .carrier-block {
    flex-flow: row wrap;
    margin-bottom: 80px;
  }
  .carrier-block .carrier-logo {
    flex: 1 0 300px;
    margin-right: 0;
    margin-bottom: 24px;
  }
  .carrier-block .carrier-logo img.logo {
    width: 232px;
    height: auto;
  }
  .carrier-block .carrier-information {
    flex: 1 1 100%;
  }
}
ul.customer-type.nav-tabs {
  padding: 0;
  height: 60px;
  background: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  border-bottom: none;
  text-align: center;
}
ul.customer-type.nav-tabs > li {
  float: none;
  display: inline-block;
  border: none;
}
ul.customer-type.nav-tabs > li:last-child > a::before {
  position: absolute;
  content: ' ';
  left: 0;
  top: 22px;
  width: 1px;
  height: 24px;
  background-color: #1B60C4;
  z-index: 1;
}
ul.customer-type.nav-tabs > li > a {
  font-size: 16px;
  line-height: 40px;
  height: 60px;
  color: #272727;
  border: none;
  padding: 15px 20px;
  position: relative;
}
ul.customer-type.nav-tabs > li > a:hover {
  border: none;
  color: #1B60C4;
  background-color: #fff;
}
ul.customer-type.nav-tabs > li > a:hover::after {
  width: 90px;
  left: calc(50% - 45px);
}
ul.customer-type.nav-tabs > li > a:active {
  border: none;
  color: #1B60C4;
}
ul.customer-type.nav-tabs > li > a:active::after {
  width: 90px;
  left: calc(50% - 45px);
}
ul.customer-type.nav-tabs > li > a:focus {
  border: none;
  color: #1B60C4;
}
ul.customer-type.nav-tabs > li > a:focus::after {
  width: 90px;
  left: calc(50% - 45px);
}
ul.customer-type.nav-tabs > li > a::after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 4px;
  bottom: 0;
  left: 50%;
  border-radius: 4px 4px 0 0;
  background-color: #0C479D;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
}
ul.customer-type.nav-tabs > li.active {
  float: none;
  display: inline-block;
  border: none;
}
ul.customer-type.nav-tabs > li.active > a {
  border: none;
  height: 60px;
  color: #0C479D;
  position: relative;
}
ul.customer-type.nav-tabs > li.active > a:hover {
  border: none;
  color: #1B60C4;
}
ul.customer-type.nav-tabs > li.active > a:active {
  border: none;
  color: #1B60C4;
}
ul.customer-type.nav-tabs > li.active > a:focus {
  border: none;
  color: #1B60C4;
}
ul.customer-type.nav-tabs > li.active::after {
  content: ' ';
  position: absolute;
  width: 90px;
  height: 4px;
  bottom: 0;
  left: calc(50% - 45px);
  border-radius: 4px 4px 0 0;
  background-color: #0C479D;
}
.features-wrapper {
  font-family: 'Libre Franklin', 'Lato', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #272727;
  margin: 80px 0;
}
.features-wrapper .features-panel {
  height: 100%;
  display: -webkit-flex;
  /* Safari */
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: stretch;
  /* flex  */
  margin-bottom: 30px;
}
.features-wrapper .features-panel .feature-box {
  flex: 1 1 32%;
}
.features-wrapper .features-panel .feature-box .feature-block {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}
.features-wrapper .features-panel .feature-box .feature-block .feature-1 {
  text-align: left;
}
.features-wrapper .features-panel .feature-box .feature-block .feature-2 {
  font-family: 'Libre Franklin-Bold', 'Libre Franklin', 'Lato', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 0.05em;
  color: #0C479D;
  margin: 8px 0px;
  text-align: left;
}
.features-wrapper .features-panel .feature-box:not(:first-child) {
  border-left: 1px solid #0C479D;
}
.customer-block {
  display: -webkit-flex;
  /* Safari */
  display: flex;
  min-height: 200px;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  /* flex  */
  padding: 60px 0;
  border-bottom: 1px solid #e6e6e6;
}
.customer-block .customer-logo {
  flex: 1 0 300px;
  margin-right: 48px;
}
.customer-block .customer-logo img.logo {
  display: block;
  max-width: 232px;
  height: auto;
  margin: auto;
}
.customer-block .customer-information {
  flex: 1 1 100%;
}
.customer-block .customer-information .customer-name {
  font-family: 'Libre Franklin', 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #0C479D;
  padding-bottom: 24px;
}
.customer-block .customer-information .customer-name span.icon-quote {
  display: inline-block;
  width: 48px;
  height: 48px;
  margin: 0px 24px -4px 0;
  background: url('../img/icon-quote.png') center center no-repeat;
  background-size: contain;
}
.customer-block .customer-information .customer-details {
  font-family: 'Libre Franklin', 'Lato', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #272727;
  margin: 24px 0px;
}
.customer-block .customer-information .customer-remarks {
  font-family: 'Libre Franklin', 'Lato', sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 18px;
  line-height: 141.2%;
  color: #272727;
  opacity: 0.75;
  margin: 24px 0px;
}
@media screen and (max-width: 992px) {
  ul.customer-type.nav-tabs {
    padding: 0;
    background: #fff;
    box-shadow: none;
    border-bottom: none;
    text-align: center;
    margin-bottom: 30px;
    padding-left: 0 !important;
  }
  ul.customer-type.nav-tabs > li {
    float: none;
    display: block;
    margin-bottom: 16px;
    border: 1px solid #1B60C4;
    background-color: #fff;
  }
  ul.customer-type.nav-tabs > li:last-child > a::before {
    content: none;
  }
  ul.customer-type.nav-tabs > li > a {
    font-size: 16px;
    line-height: 30px;
    height: 60px;
    color: #272727;
    background-color: #fff;
    border: none;
    padding: 15px 20px;
    position: relative;
    margin-right: 0;
  }
  ul.customer-type.nav-tabs > li > a:hover {
    border: none;
    background-color: #1B60C4;
    color: #fff;
  }
  ul.customer-type.nav-tabs > li > a:hover::after {
    content: none;
  }
  ul.customer-type.nav-tabs > li > a:active {
    border: none;
    background-color: #0C479D;
    color: #fff;
  }
  ul.customer-type.nav-tabs > li > a:active::after {
    content: none;
  }
  ul.customer-type.nav-tabs > li > a:focus {
    border: none;
    color: #0C479D;
  }
  ul.customer-type.nav-tabs > li > a:focus::after {
    content: none;
  }
  ul.customer-type.nav-tabs > li > a::after {
    content: none;
  }
  ul.customer-type.nav-tabs > li.active {
    float: none;
    display: block;
    border: 1px solid #0C479D;
    background-color: #0C479D;
    color: #fff;
  }
  ul.customer-type.nav-tabs > li.active > a {
    border: none;
    height: 60px;
    color: #fff;
    background-color: #0C479D;
    position: relative;
    margin-right: 0;
  }
  ul.customer-type.nav-tabs > li.active > a:hover {
    border: none;
    color: #fff;
    background-color: #1B60C4;
  }
  ul.customer-type.nav-tabs > li.active > a:active {
    border: none;
    color: #fff;
    background-color: #0C479D;
  }
  ul.customer-type.nav-tabs > li.active > a:focus {
    border: none;
    color: #fff;
    background-color: #0C479D;
  }
  ul.customer-type.nav-tabs > li.active::after {
    content: none;
  }
  .features-wrapper {
    margin: 80px 0;
    padding: 40px 0;
  }
  .features-wrapper .features-panel {
    display: none;
  }
  .customer-block {
    flex-flow: row wrap;
    margin-bottom: 80px;
  }
  .customer-block .customer-logo {
    flex: 1 0 300px;
    margin-right: 0;
    margin-bottom: 24px;
  }
  .customer-block .customer-logo img.logo {
    max-width: 232px;
    height: auto;
  }
  .customer-block .customer-information {
    flex: 1 1 100%;
  }
}
.content-section.contact-us {
  padding-left: 200px;
  padding-right: 200px;
}
.content-section.contact-us .required-field {
  color: #E75A5B;
}
.content-section.contact-us .form-group {
  padding-right: 15px;
}
.content-section.contact-us .form-control {
  border-radius: 7px;
  height: 55px;
  color: #272727;
  font-size: 16px;
  padding: 20px 20px 20px 30px;
}
.content-section.contact-us select.form-control {
  padding: 6px 20px 6px 30px;
}
.content-section.contact-us textarea.form-control {
  height: auto;
  padding: 16px 20px 16px 30px;
}
.content-section.contact-us .hs_submit {
  text-align: center;
  margin-top: 40px;
}
.content-section.contact-us .hs-form-field {
  position: relative;
}
.content-section.contact-us span.hs-form-required {
  position: absolute;
  color: #E75A5B;
  left: 15px;
  top: 18px;
}
.contact-info-block {
  display: -webkit-flex;
  /* Safari */
  display: flex;
  min-height: 372px;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  /* flex  */
  padding: 60px 0;
}
.contact-info-block .headquarters {
  flex: 0 1 50%;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  min-height: 200px;
  padding-bottom: 40px;
}
.contact-info-block .headquarters .title {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #0C479D;
  margin-bottom: 20px;
}
.contact-info-block .representative {
  flex: 1 0 50%;
}
.contact-info-block .representative .title {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #0C479D;
  margin-bottom: 20px;
}
.contact-info-block .representative .office-list {
  display: -webkit-flex;
  /* Safari */
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  /* flex  */
}
.contact-info-block .representative .office-list .office-info {
  flex: 1 0 50%;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
}
@media screen and (max-width: 992px) {
  .content-section.contact-us {
    padding-left: 20px;
    padding-right: 20px;
  }
  .contact-info-block {
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    /* flex  */
    padding: 60px 0;
  }
  .contact-info-block .headquarters {
    flex: 1 1 100%;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    min-height: 200px;
  }
  .contact-info-block .representative {
    flex: 1 1 100%;
  }
  .contact-info-block .representative .office-list {
    display: -webkit-flex;
    /* Safari */
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    /* flex  */
  }
  .contact-info-block .representative .office-list .office-info {
    flex: 1 0 50%;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    padding-bottom: 30px;
  }
}
@media screen and (max-width: 680px) {
  .contact-info-block .representative .office-list {
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    /* flex  */
  }
  .contact-info-block .representative .office-list .office-info {
    flex: 1 1 100%;
  }
}
.container-content.with-side-menu-layout {
  display: flex;
  flex-direction: row;
}
@media (max-width: 768px) {
  .container-content.with-side-menu-layout {
    flex-direction: column;
  }
}
.container-content.with-side-menu-layout .content-intro {
  flex: 1 1 80%;
}
.side-menu {
  flex: 1 0 200px;
  margin: 0 24px 0 80px;
}
@media (max-width: 768px) {
  .side-menu {
    margin-left: 0px;
  }
}
.side-menu-title {
  font-family: Libre Franklin;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.02em;
  color: #272727;
  margin-bottom: 24px;
}
.side-menu-title:before {
  content: '';
  display: inline-block;
  background: linear-gradient(142.7deg, #0C479D 4.3%, #006CD3 144.63%);
  width: 8px;
  height: 26px;
  margin-right: 14px;
  border-top-right-radius: 24px;
  border-bottom-left-radius: 24px;
}
.side-menu-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed #C4C4C4;
}
.side-menu-list-item a {
  flex-grow: 1;
  display: block;
  font-family: Libre Franklin;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  padding: 14px 20px 14px 0;
  color: #2F2F2F;
  transition: color 0.3s ease, transform 0.3s ease;
  white-space: nowrap;
}
.side-menu-list-item a.active {
  font-weight: 600;
  color: #0c479d;
}
.side-menu-list-item:after {
  opacity: 0;
  display: inline-block;
  content: "\f061";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 14px;
  color: #ED8000;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translateX(-24px);
}
.side-menu-list-item:hover a {
  color: #ED8000;
  transform: translateX(4px);
}
.side-menu-list-item:hover:after {
  opacity: 1;
  transform: translateX(0px);
}
.industry-awards.content-page {
  position: relative;
  z-index: 1;
}
.industry-awards.content-page .contact-us {
  display: flex;
  margin-top: -160px;
}
.industry-awards.content-page .contact-us .contact-us-panel {
  margin-top: 0;
  align-self: center;
}
.content-wrapper .up-100 {
  margin-bottom: -100px;
  transform: translateY(-100px);
  -ms-transform: translateY(-100px);
  /* IE 9 */
  -moz-transform: translateY(-100px);
  /* Firefox */
  -webkit-transform: translateY(-100px);
  /* Safari 和 Chrome */
  -o-transform: translateY(-100px);
  /* Opera */
}
.content-wrapper .section-bg {
  width: 100%;
}
.content-wrapper .section-bg.bg-grey {
  position: relative;
}
.content-wrapper .section-bg.bg-grey::after {
  position: absolute;
  content: ' ';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #F1F7FF;
  filter: blur(100px);
}
.content-wrapper .container-content {
  max-width: 1320px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.content-wrapper .container-content::before {
  display: table;
  content: " ";
}
.content-wrapper .container-content::after {
  display: table;
  content: " ";
  clear: both;
}
.content-wrapper .container-content .content-intro {
  padding-bottom: 10rem;
}
.content-wrapper .container-content .content-intro .page-title {
  font-family: 'Raleway-bold', 'Raleway', 'Libre Franklin', 'Lato', sans-serif;
  font-weight: 800;
  font-size: 32px;
  line-height: 38px;
  color: #0C479D;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0 0 24px 0px;
}
.content-wrapper .container-content .content-intro .post-time {
  font-family: 'Raleway', 'Libre Franklin', 'Lato', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.75);
}
.content-wrapper .container-content .page-title {
  font-family: 'Raleway-bold', 'Raleway', 'Libre Franklin', 'Lato', sans-serif;
  font-weight: 800;
  font-size: 32px;
  line-height: 38px;
  color: #0C479D;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0 0 24px 0px;
}
.content-wrapper .container-content .post-time {
  font-family: 'Raleway', 'Libre Franklin', 'Lato', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.75);
}
.content-wrapper .container-content p {
  margin-top: 12px;
}
.content-wrapper .container-content ul {
  margin: 20px 0;
  padding-left: 36px;
}
.content-wrapper .container-content ul ul {
  margin: 0;
}
.content-wrapper .container-content .img-response {
  max-width: 100%;
  height: auto;
  border: none;
  margin: 20px auto;
}
.content-wrapper .container-content .l1-title {
  font-family: 'Raleway', 'Libre Franklin', 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #ED8000;
  margin-bottom: 20px;
  margin-top: 50px;
}
.content-wrapper .container-content .l2-title {
  font-family: 'Raleway', 'Libre Franklin', 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #0C479D;
  margin: 30px 0;
}
.content-wrapper .container-content .l3-title {
  font-family: 'Libre Franklin', 'Lato', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #0C479D;
  margin: 30px 0 8px 0;
}
.content-wrapper .container-content .content-block {
  display: -webkit-flex;
  /* Safari */
  display: flex;
  min-height: 372px;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;
  /* flex  */
  padding: 5rem 0;
}
.content-wrapper .container-content .content-block .content-block-section {
  flex: 0 1 50%;
  margin-left: 0;
  margin-right: 36px;
}
.content-wrapper .container-content .content-block .content-block-section .content-block-section-icon {
  width: 48px;
  height: 48px;
  margin-bottom: 20px;
  animation: icon-move 4s infinite;
  -webkit-animation: icon-move 4s infinite;
  /* Safari 和 Chrome */
}
@keyframes icon-move {
  0% {
    transform: translateY(0px);
    -ms-transform: translateY(0px);
    /* IE 9 */
    -moz-transform: translateY(0px);
    /* Firefox */
    -webkit-transform: translateY(0px);
    /* Safari 和 Chrome */
    -o-transform: translateY(0px);
    /* Opera */
  }
  10% {
    transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    /* IE 9 */
    -moz-transform: translateY(-2px);
    /* Firefox */
    -webkit-transform: translateY(-2px);
    /* Safari 和 Chrome */
    -o-transform: translateY(-2px);
    /* Opera */
  }
  30% {
    transform: translateY(2px);
    -ms-transform: translateY(2px);
    /* IE 9 */
    -moz-transform: translateY(2px);
    /* Firefox */
    -webkit-transform: translateY(2px);
    /* Safari 和 Chrome */
    -o-transform: translateY(2px);
    /* Opera */
  }
  40% {
    transform: translateY(0px);
    -ms-transform: translateY(0px);
    /* IE 9 */
    -moz-transform: translateY(0px);
    /* Firefox */
    -webkit-transform: translateY(0px);
    /* Safari 和 Chrome */
    -o-transform: translateY(0px);
    /* Opera */
  }
  60% {
    transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    /* IE 9 */
    -moz-transform: rotate(-360deg);
    /* Firefox */
    -webkit-transform: rotate(-360deg);
    /* Safari 和 Chrome */
    -o-transform: rotate(-360deg);
    /* Opera */
  }
}
.content-wrapper .container-content .content-block .content-block-section .content-block-section-icon.icon-visibility {
  background: url('../img/solutions/icon-eye.png') center center no-repeat;
  background-size: contain;
}
.content-wrapper .container-content .content-block .content-block-section .content-block-section-icon.icon-execution {
  background: url('../img/solutions/icon-gears.png') center center no-repeat;
  background-size: contain;
}
.content-wrapper .container-content .content-block .content-block-section .content-block-section-icon.icon-collaboration {
  background: url('../img/solutions/icon-collaboration.png') center center no-repeat;
  background-size: contain;
}
.content-wrapper .container-content .content-block .content-block-section .content-block-section-icon.icon-compliance {
  background: url('../img/solutions/icon-compliance.png') center center no-repeat;
  background-size: contain;
}
.content-wrapper .container-content .content-block .content-block-section .content-block-title {
  font-family: 'Raleway-Bold', 'Raleway', 'Libre Franklin', 'Lato', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 38px;
  margin-bottom: 20px;
  color: #0C479D;
}
.content-wrapper .container-content .content-block .content-block-section .content-block-desc {
  margin-bottom: 68px;
}
.content-wrapper .container-content .content-block .content-block-section .button.btn.btn-blue {
  margin-bottom: 20px;
}
.content-wrapper .container-content .content-block .content-block-image {
  flex: 0 1 50%;
  min-height: 240px;
  margin-left: 36px;
  margin-right: 0;
  position: relative;
  overflow: hidden;
}
.content-wrapper .container-content .content-block .content-block-image::after {
  content: ' ';
  position: absolute;
  z-index: -1;
  width: 720px;
  height: 384px;
  right: -238px;
  bottom: -105px;
  background: url('../img/solutions/bg-img-dot.png') center center no-repeat;
  background-size: cover;
}
.content-wrapper .container-content .content-block.set-right {
  flex-flow: row-reverse nowrap;
}
.content-wrapper .container-content .content-block.set-right .content-block-section {
  margin-left: 36px;
  margin-right: 0;
}
.content-wrapper .container-content .content-block.set-right .content-block-image {
  margin-left: 0;
  margin-right: 36px;
}
.content-wrapper .container-content .content-block.set-right .content-block-image::after {
  content: ' ';
  position: absolute;
  z-index: -1;
  left: -238px;
  bottom: -105px;
}
.page-bottom-row {
  margin: 80px 0 80px 0;
}
.page-bottom-row .btn.btn-blue span.fa {
  margin-left: 8px;
  margin-right: 8px;
}
.page-bottom-row .btn.btn-blue + .btn.btn-blue {
  margin-left: 44px;
}
@media screen and (max-width: 992px) {
  .content-wrapper .container-content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .content-wrapper .container-content .content-block {
    flex-flow: column-reverse wrap;
    justify-content: space-between;
    align-items: stretch;
    /* flex  */
  }
  .content-wrapper .container-content .content-block .content-block-section {
    flex: 1 1 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .content-wrapper .container-content .content-block .content-block-section .content-block-section-icon {
    width: 48px;
    height: 48px;
    margin-bottom: 20px;
  }
  .content-wrapper .container-content .content-block .content-block-section .content-block-title {
    font-family: 'Raleway-Bold', 'Raleway', 'Libre Franklin', 'Lato', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 20px;
  }
  .content-wrapper .container-content .content-block .content-block-section .content-block-desc {
    margin-bottom: 20px;
  }
  .content-wrapper .container-content .content-block .content-block-section .button.btn.btn-blue {
    margin-bottom: 0;
  }
  .content-wrapper .container-content .content-block .content-block-image {
    flex: 1 1 100%;
    min-height: 240px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 40px;
    position: relative;
  }
  .content-wrapper .container-content .content-block .content-block-image::after {
    content: ' ';
    position: absolute;
    z-index: -1;
    width: 720px;
    height: 384px;
    right: -238px;
    bottom: -105px;
    background: url('../img/solutions/bg-img-dot.png') center center no-repeat;
    background-size: cover;
  }
  .content-wrapper .container-content .content-block.set-right {
    flex-flow: column-reverse wrap;
    margin-left: 0;
    margin-right: 0;
  }
  .content-wrapper .container-content .content-block.set-right .content-block-section {
    flex: 1 1 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .content-wrapper .container-content .content-block.set-right .content-block-section .content-block-desc {
    margin-bottom: 20px;
  }
  .content-wrapper .container-content .content-block.set-right .content-block-section .button.btn.btn-blue {
    margin-bottom: 0;
  }
  .content-wrapper .container-content .content-block.set-right .content-block-image::after {
    content: ' ';
    position: absolute;
    z-index: -1;
    left: -238px;
    bottom: -105px;
  }
}
/* - common override -*/
h4 {
  line-height: 1.5;
}
