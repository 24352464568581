.carrier-block{
    display: -webkit-flex; /* Safari */
    display: flex;
    min-height: 200px;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center; /* flex  */
    padding:60px 0;
    border-bottom: 1px solid #e6e6e6;
    .carrier-logo {
        flex: 1 0 300px;
        margin-right: 48px;   
        img.logo{
            display: block;
            width: 232px;
            height: auto;
            margin: auto;
        }
    }
    .carrier-information{
        flex: 1 1 100%;
        .carrier-name{
            font-family: Libre Franklin;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            color: #0C479D;
            padding-bottom: 24px;
        }
    }
}
@media screen and (max-width:992px){   
    .carrier-block{
        flex-flow: row wrap;
        margin-bottom: 80px;
        .carrier-logo {
            flex: 1 0 300px;
            margin-right: 0;   
            margin-bottom: 24px;
            img.logo{
                width: 232px;
                height: auto;                
            }
        }
        .carrier-information{
            flex: 1 1 100%;      
        }
    }
}