.icon-task-list{

}
.icon-gears{

}
.icon-collaboration{
    
}
.icon-48{
    width: 48px;
    height: 48px;
}