.news-gallery{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &-item{
        flex: 0 0 33.3333%;
        @media (max-width: 1024px) { 
            flex-basis: 50%;
        }
        @media (max-width: 768px) { 
            flex-basis: 100%;
        }
        padding: 18px;
        &-wrapper{
            height: 560px;
            @media (max-width: 1024px) { 
                height: 520px;
            }
            @media (max-width: 768px) { 
                height: 480px;
            }
            display: flex;
            flex-direction: column;
            overflow: hidden;
            background-color: #fff;
            border-radius: 8px;
            box-shadow: 0px 12px 48px 0px rgba(23, 26, 28, 0.03),0px 9px 28px 0px rgba(0, 0, 0, 0.05),0px 6px 16px 0px rgba(0, 0, 0, 0.08);
        }
        &-content{
            flex-grow: 1;
            padding: 40px 40px 24px 40px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            &-title{
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 32px;
                color: @cs-color-primary;
                margin-top: 16px;
                &:hover{
                    cursor: pointer;
                }
            }
            &-date{
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 16px;
                color: rgba(0, 0, 0, 0.75);
            }
            &-detail{
                margin-top: 16px;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                flex-grow: 1;
                overflow: hidden;
                position: relative;
                &>p{
                    line-height: 24px;
                    margin-bottom: 0;
                }
                &:after{
                    display: block;
                    position: absolute;
                    width: 100%;
                    bottom: 0;
                    left: 0;
                    content: '';
                    height: 48px;
                    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 100) 60%);
                }
            }
        }
        &-img{
            flex-basis: 280px;
            flex-grow: 0;
            flex-shrink: 0;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }
}