.content-section.contact-us{
    padding-left: 200px;
    padding-right: 200px;
    .required-field{
        color:#E75A5B;
    }
    .form-group{
        padding-right: 15px;
    }
    .form-control{
        border-radius: 7px;
        height: 55px;
        color: #272727;
        font-size: 16px;
        padding: 20px 20px 20px 30px;
    }
    select.form-control{
        padding: 6px 20px 6px 30px;
    }
    textarea.form-control{
        height: auto;
        padding: 16px 20px 16px 30px;
    }
    .hs_submit {
        text-align: center;
        margin-top: 40px;
    }
    .hs-form-field{
        position: relative;
    }
    span.hs-form-required{
        position: absolute;
        color:#E75A5B;
        left: 15px;
        top: 18px;
    }
}
.contact-info-block{
    display: -webkit-flex; /* Safari */
    display: flex;
    min-height: 372px;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start; /* flex  */   
    padding:60px 0;
    .headquarters{
        flex: 0 1 50%;
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
        min-height: 200px;
        padding-bottom: 40px;
        .title{
            font-weight: 600;
            font-size: 24px;
            line-height: 28px;
            color: #0C479D;
            margin-bottom: 20px;
        }
    }
    .representative{
        flex: 1 0 50%;
        .title{
            font-weight: 600;
            font-size: 24px;
            line-height: 28px;
            color: #0C479D;
            margin-bottom: 20px;
        }
        .office-list {
            display: -webkit-flex; /* Safari */
            display: flex;
            flex-flow: row nowrap;         
            justify-content: space-between;
            align-items: flex-start; /* flex  */   
            .office-info{
                flex: 1 0 50%;
                font-weight: normal;
                font-size: 16px;
                line-height: 26px;
            } 
        }
    }
    
}

@media screen and (max-width:992px){   
    .content-section.contact-us{
        padding-left:20px;
        padding-right: 20px;
    }
    .contact-info-block{
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: flex-start; /* flex  */   
        padding:60px 0;
        .headquarters{
            flex: 1 1 100%;
            font-weight: normal;
            font-size: 16px;
            line-height: 26px;
            min-height: 200px;
            
        }
        .representative{
            flex: 1 1 100%;
            .office-list {
                display: -webkit-flex; /* Safari */
                display: flex;
                flex-flow: row nowrap;         
                justify-content: space-between;
                align-items: flex-start; /* flex  */   
                .office-info{
                    flex: 1 0 50%;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 26px;
                    padding-bottom: 30px;
                } 
            }
        }
        
    }
}
@media screen and (max-width:680px){   
    .contact-info-block{        
        .representative{
            .office-list {
                flex-flow: row wrap;         
                justify-content: flex-start;
                align-items: flex-start; /* flex  */   
                .office-info{
                    flex: 1 1 100%;
                } 
            }
        }
        
    }
}