ul.customer-type.nav-tabs{
    padding: 0;
    height: 60px;
    background: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    border-bottom: none;
    text-align: center;
    &>li{
        float: none;
        display: inline-block;
        border: none;
        &:last-child{
            &>a{
                &::before{
                    position: absolute;
                    content: ' ';
                    left: 0;
                    top:22px;
                    width: 1px;
                    height: 24px;
                    background-color: #1B60C4;         
                    z-index: 1;           
                }
            }
        }
        &>a{
            font-size: 16px;
            line-height: 40px;
            height: 60px;
            color: #272727;
            border: none;
            padding: 15px 20px;
            position: relative;
            &:hover{
                border: none;
                color: #1B60C4;
                background-color: #fff;
                &::after{
                    width: 90px;
                    left: calc(50% - 45px);
                }
            }
            &:active{
                border: none;
                color: #1B60C4;
                &::after{
                    width: 90px;
                    left: calc(50% - 45px);
                }
            }
            &:focus{
                border: none;
                color: #1B60C4;
                &::after{
                    width: 90px;
                    left: calc(50% - 45px);
                }
            }
            &::after{
                content:' ';
                position: absolute;
                width:0;
                height: 4px;
                bottom: 0;
                left:50%;
                border-radius: 4px 4px 0 0;
                background-color: #0C479D;
                -webkit-transition: all .15s ease;
                -moz-transition: all .15s ease;
                -ms-transition: all .15s ease;
                -o-transition: all .15s ease;
                transition: all .15s ease;
            }
        }
    }
    &>li.active{
        float: none;
        display: inline-block;
        border: none;        
        &>a{
            border: none;
            height: 60px;
            color:#0C479D;
            position: relative;
            &:hover{
                border: none;
                color: #1B60C4;
            }
            &:active{
                border: none;
                color: #1B60C4;
            }
            &:focus{
                border: none;
                color: #1B60C4;
            }
        }
        &::after{
            content:' ';
            position: absolute;
            width:90px;
            height: 4px;
            bottom: 0;
            left: calc(50% - 45px);
            border-radius: 4px 4px 0 0;
            background-color: #0C479D;
        }
    }
}

.features-wrapper{
    font-family: 'Libre Franklin', 'Lato', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #272727;
    margin: 80px 0;
    .features-panel{
        height: 100%;
        display: -webkit-flex; /* Safari */
        display: flex;
        flex-flow: row nowrap;
        justify-content:space-around;
        align-items: stretch; /* flex  */
        margin-bottom: 30px;
        .feature-box{
            flex: 1 1 32%;
            .feature-block{ 
                display: inline-block;
                margin-left:auto;
                margin-right: auto;               
                .feature-1{
                    text-align: left;
                }
                .feature-2{
                    font-family: 'Libre Franklin-Bold', 'Libre Franklin', 'Lato', sans-serif;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 36px;
                    line-height: 44px;
                    letter-spacing: 0.05em;
                    color: #0C479D;
                    margin: 8px 0px;
                    text-align: left;
                }
            }
            &:not(:first-child){
                border-left:1px solid #0C479D;
            }
        }
        
    }
}

.customer-block{
    display: -webkit-flex; /* Safari */
    display: flex;
    min-height: 200px;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center; /* flex  */
    padding:60px 0;
    border-bottom: 1px solid #e6e6e6;
    .customer-logo {
        flex: 1 0 300px;
        margin-right: 48px;   
        img.logo{
            display: block;
            max-width: 232px;
            height: auto;
            margin: auto;

        }
    }
    .customer-information{
        flex: 1 1 100%;
        .customer-name{
            span.icon-quote{
                display: inline-block;
                width: 48px;
                height: 48px;
                margin: 0px 24px -4px 0;
                background: url('../img/icon-quote.png') center center no-repeat;
                background-size: contain;
            }
            font-family: 'Libre Franklin', 'Lato', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            color: #0C479D;
            padding-bottom: 24px;
        }
        .customer-details{
            font-family: 'Libre Franklin', 'Lato', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 26px;
            color: #272727;
            margin: 24px 0px;
        }
        .customer-remarks{
            font-family: 'Libre Franklin', 'Lato', sans-serif;
            font-style: italic;
            font-weight: normal;
            font-size: 18px;
            line-height: 141.2%;
            color: #272727;
            opacity: 0.75;
            margin: 24px 0px;
        }
    }
}
@media screen and (max-width:992px){   
    ul.customer-type.nav-tabs{
        padding: 0;
        background: #fff;
        box-shadow: none;
        border-bottom: none;
        text-align: center;
        margin-bottom: 30px;
        padding-left: 0 !important;
        &>li{
            float: none;
            display: block;
            margin-bottom: 16px;
            border: 1px solid #1B60C4;
            background-color: #fff;
            &:last-child{
                &>a{
                    &::before{
                        content: none;       
                    }
                }
            }
            &>a{
                font-size: 16px;
                line-height: 30px;
                height: 60px;
                color: #272727;
                background-color: #fff;
                border: none;
                padding: 15px 20px;
                position: relative;
                margin-right: 0;

                &:hover{
                    border: none;
                    background-color: #1B60C4;
                    color: #fff;
                    &::after{
                        content: none;
                    }
                }
                &:active{
                    border: none;
                    background-color:  #0C479D;
                    color: #fff;
                    &::after{
                        content: none;
                    }
                }
                &:focus{
                    border: none;
                    color: #0C479D;
                    &::after{
                        content: none;
                    }
                }
                &::after{
                    content: none;
                }
            }
        }
        &>li.active{
            float: none;
            display: block;
            border: 1px solid #0C479D; 
            background-color: #0C479D; 
            color:#fff;
            &>a{
                border: none;
                height: 60px;
                color:#fff;
                background-color: #0C479D;
                position: relative;
                margin-right: 0;
                &:hover{
                    border: none;
                    color:#fff;
                    background-color: #1B60C4;
                }
                &:active{
                    border: none;
                    color:#fff;
                    background-color: #0C479D;
                }
                &:focus{
                    border: none;
                    color:#fff;
                    background-color: #0C479D;
                }
            }
            &::after{
                content:none;
            }
        }
    }
    .features-wrapper{
        margin: 80px 0;
        padding: 40px 0;
        .features-panel{
            display: none;            
        }
    }
    .customer-block{
        flex-flow: row wrap;
        margin-bottom: 80px;
        .customer-logo {
            flex: 1 0 300px;
            margin-right: 0;   
            margin-bottom: 24px;
            img.logo{
                max-width: 232px;
                height: auto;                
            }
        }
        .customer-information{
            flex: 1 1 100%;        
        }
    }
}