.content-wrapper{
    .container-content{        
        a.btn-collapse-title{
            display: block;
            position: relative;
            color: #00479d;
            padding:24px 40px;
            margin-bottom: 0;
            font-size: 20px;
            line-height: 24px;
            background-color: #f0f6ff;
            border-bottom: 1px solid #e6e6e6;
            -webkit-transition: all .15s ease;
            -moz-transition: all .15s ease;
            -ms-transition: all .15s ease;
            -o-transition: all .15s ease;
            transition: all .15s ease;
            &:hover{
                color: #00479d;
                background-color: #f0f6ff;
            }
            &:active{
                color: #00479d;
                background-color: #f0f6ff;                
            }
            &::before{
                content: ' ';
                position: absolute;
                left: 16px;
                top: 32px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                border:3px solid #00479d;
            }
            span.icon{
                position: absolute;
                display: block;
                width: 14px;
                height: 14px;
                right: 16px;
                top: 30px;
                color: #00479d;
                transform: rotate(90deg);
                -ms-transform: rotate(90deg); /* IE 9 */
                -moz-transform: rotate(90deg);   /* Firefox */
                -webkit-transform: rotate(90deg);  /* Safari 和 Chrome */
                -o-transform: rotate(90deg);  /* Opera */
                -webkit-transition: all .15s ease;
                -moz-transition: all .15s ease;
                -ms-transition: all .15s ease;
                -o-transition: all .15s ease;
                transition: all .15s ease;
            }
        }
        a.btn-collapse-title.collapsed{
            color: #272727;
            background-color: transparent;    
            &:hover{
                color: #00479d;
                background-color: #f0f6ff;
                span.icon{
                    color: #00479d;
                }
            }
            &:active{
                color: #00479d;
                background-color: #f0f6ff;
            }
            span.icon{
                color: #a6a6a6;
                transform: rotate(0deg);
                -ms-transform: rotate(0deg); /* IE 9 */
                -moz-transform: rotate(0deg);   /* Firefox */
                -webkit-transform: rotate(0deg);  /* Safari 和 Chrome */
                -o-transform: rotate(0deg);  /* Opera */
            }
        }
        .faq-collapse-panel{
            padding: 20px;
            background: #F8FBFF;
            table{
                tr{
                    th{
                        vertical-align: middle;
                        background-color: #F2F2F2;
                        padding:12px 20px;
                    }
                    td{
                        background-color: #fff;
                        padding:12px 20px;
                    }
                }
            }
        }
    }
}