.footer .footer-wrapper{
    margin: 16px auto;
    max-width: 1280px;
    @media (max-width: 1200px)  {
        flex-direction: column;
        white-space:normal;
        word-break:normal;
        text-align:center;
        line-height: 24px;
        margin-top: 16px;
        &>.footer-left{
            &>.copy-right,
            &>.footer-menu{
                margin-bottom: 16px;
            }
        }
        .footer-right{
        }
    }
}

.industry-awards{
    .contact-us-panel{
        @media (max-width: 768px){
            &>.sub-title{
                font-size: 16px;
            }
            &>.title{
                font-size: 24px;
            }
        }
    }
}