// import less list
@import url(icons.less);
@import url(variables.less);
@import url(press-release.less);
@import url(footer.less);
@import url(content.less);
@import url(faq.less);
@import url(carriers.less);
@import url(success-story.less);
@import url(contact-us.less);
@import url(side-menu.less);

// define rem
html{
    // default rem
}

// type of footer
.industry-awards.content-page{
    position: relative;
    z-index: 1;
    .contact-us{
        display: flex;
        margin-top: -160px;
        .contact-us-panel{
            margin-top: 0;
            align-self: center;
        }
    }
}

.content-wrapper{     
    .up-100{
        margin-bottom: -100px;
        transform: translateY(-100px);
        -ms-transform: translateY(-100px); /* IE 9 */
        -moz-transform: translateY(-100px);   /* Firefox */
        -webkit-transform: translateY(-100px);  /* Safari 和 Chrome */
        -o-transform: translateY(-100px);  /* Opera */
    }
    .section-bg{
        width: 100%;        
    }
    .section-bg.bg-grey{
        position: relative;
        &::after{
            position: absolute;
            content: ' ';
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            background-color: #F1F7FF;            
            filter: blur(100px);
        }
    }
    .container-content{
        max-width: 1320px;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        &::before{ 
            display: table;
            content: " ";
        }
        &::after{ 
            display: table;
            content: " ";
            clear: both;
        }
        .content-intro{
            padding-bottom: 10rem;
            .page-title{
                font-family: 'Raleway-bold','Raleway', 'Libre Franklin', 'Lato', sans-serif;
                font-weight: 800;
                font-size: 32px;
                line-height: 38px;
                color: #0C479D;
                flex: none;
                order: 0;
                flex-grow: 0;
                margin: 0 0 24px 0px;
            }
            .post-time{
                font-family: 'Raleway', 'Libre Franklin', 'Lato', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                color: rgba(0, 0, 0, 0.75);
            }            
        }
        .page-title{
            font-family: 'Raleway-bold','Raleway', 'Libre Franklin', 'Lato', sans-serif;
            font-weight: 800;
            font-size: 32px;
            line-height: 38px;
            color: #0C479D;
            flex: none;
            order: 0;
            flex-grow: 0;
            margin: 0 0 24px 0px;
        }
        .post-time{
            font-family: 'Raleway', 'Libre Franklin', 'Lato', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            color: rgba(0, 0, 0, 0.75);
        }      
        p{
            margin-top: 12px;
        }
        ul{
            margin: 20px 0;
            padding-left: 36px;
            ul{
                margin: 0;
            }
        }
        .img-response{
            max-width: 100%;
            height: auto;
            border: none;
            margin:20px auto;
        }
        .l1-title{
            font-family: 'Raleway', 'Libre Franklin', 'Lato', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 28px;
            color: #ED8000;
            margin-bottom: 20px;
            margin-top: 50px;
        }
        .l2-title{
            font-family: 'Raleway', 'Libre Franklin', 'Lato', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 28px;
            color: #0C479D;
            margin: 30px 0;

        }
        .l3-title{
            font-family: 'Libre Franklin', 'Lato', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            color: #0C479D;
            margin: 30px 0 8px 0;
        }
        .content-block{
            display: -webkit-flex; /* Safari */
            display: flex;
            min-height: 372px;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: stretch; /* flex  */
            padding:5rem 0;
            .content-block-section{
                flex: 0 1 50%;
                margin-left: 0;
                margin-right: 36px;   
                .content-block-section-icon{
                    width: 48px;
                    height: 48px;
                    margin-bottom: 20px;
                    animation:icon-move 4s infinite;
                    -webkit-animation:icon-move 4s infinite; /* Safari 和 Chrome */
                }
                @keyframes icon-move {
                    0%   { 
                        transform: translateY(0px);
                        -ms-transform: translateY(0px); /* IE 9 */
                        -moz-transform: translateY(0px);   /* Firefox */
                        -webkit-transform: translateY(0px);  /* Safari 和 Chrome */
                        -o-transform: translateY(0px);  /* Opera */
                    }
                    10%   { 
                        transform: translateY(-2px);
                        -ms-transform: translateY(-2px); /* IE 9 */
                        -moz-transform: translateY(-2px);   /* Firefox */
                        -webkit-transform: translateY(-2px);  /* Safari 和 Chrome */
                        -o-transform: translateY(-2px);  /* Opera */
                    }
                    30%   { 
                        transform: translateY(2px);
                        -ms-transform: translateY(2px); /* IE 9 */
                        -moz-transform: translateY(2px);   /* Firefox */
                        -webkit-transform: translateY(2px);  /* Safari 和 Chrome */
                        -o-transform: translateY(2px);  /* Opera */
                    }
                    40%   { 
                        transform: translateY(0px);
                        -ms-transform: translateY(0px); /* IE 9 */
                        -moz-transform: translateY(0px);   /* Firefox */
                        -webkit-transform: translateY(0px);  /* Safari 和 Chrome */
                        -o-transform: translateY(0px);  /* Opera */
                    }
                    60%   { 
                        transform: rotate(-360deg);
                        -ms-transform: rotate(-360deg); /* IE 9 */
                        -moz-transform: rotate(-360deg);   /* Firefox */
                        -webkit-transform: rotate(-360deg);  /* Safari 和 Chrome */
                        -o-transform: rotate(-360deg);  /* Opera */
                    }                    
                    
                }
                .content-block-section-icon.icon-visibility{
                    background: url('../img/solutions/icon-eye.png') center center no-repeat;
                    background-size: contain;
                }
                .content-block-section-icon.icon-execution{
                    background: url('../img/solutions/icon-gears.png') center center no-repeat;
                    background-size: contain;
                }
                .content-block-section-icon.icon-collaboration{
                    background: url('../img/solutions/icon-collaboration.png') center center no-repeat;
                    background-size: contain;
                }
                .content-block-section-icon.icon-compliance{
                    background: url('../img/solutions/icon-compliance.png') center center no-repeat;
                    background-size: contain;
                }
                .content-block-title{
                    font-family: 'Raleway-Bold', 'Raleway', 'Libre Franklin', 'Lato', sans-serif;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 32px;
                    line-height: 38px;
                    margin-bottom: 20px;
                    color: #0C479D;
                }
                .content-block-desc{
                    margin-bottom: 68px;
                }
                .button.btn.btn-blue{
                    margin-bottom: 20px;
                }
            }
            .content-block-image{
                flex: 0 1 50%;
                min-height: 240px;
                margin-left: 36px;
                margin-right: 0;
                position: relative;
                overflow: hidden;
                &::after{
                    content: ' ';
                    position: absolute;
                    z-index: -1;
                    width: 720px;
                    height: 384px;
                    right: -238px;
                    bottom: -105px; 
                    background: url('../img/solutions/bg-img-dot.png') center center no-repeat;
                    background-size: cover;
                }
            }
        }
        .content-block.set-right{
            flex-flow: row-reverse nowrap;
            .content-block-section{
                margin-left: 36px;
                margin-right: 0;   
            }
            .content-block-image{
                margin-left: 0;
                margin-right: 36px;         
                &::after{
                    content: ' ';
                    position: absolute;
                    z-index: -1;
                    left: -238px;
                    bottom: -105px; 
                }      
            }
        }
    }
    
}
.page-bottom-row{
    margin: 80px 0 80px 0;
    .btn.btn-blue{
        span.fa{
            margin-left: 8px;
            margin-right: 8px;
        }
        & + .btn.btn-blue{
            margin-left: 44px;
        }
    }
}

@media screen and (max-width:992px){   
    .content-wrapper{
        .container-content{
            padding-left: 20px;
            padding-right: 20px;
            .content-block{        
                flex-flow: column-reverse wrap;
                justify-content: space-between;
                align-items: stretch; /* flex  */
                .content-block-section{
                    flex: 1 1 100%;
                    margin-left: 0;
                    margin-right: 0;   
                    .content-block-section-icon{
                        width: 48px;
                        height: 48px;
                        margin-bottom: 20px;
                        
                    }
                    .content-block-title{
                        font-family: 'Raleway-Bold', 'Raleway', 'Libre Franklin', 'Lato', sans-serif;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 32px;
                        line-height: 38px;
                        margin-bottom: 20px;
                    }
                    .content-block-desc{
                        margin-bottom: 20px;
                    }
                    .button.btn.btn-blue{
                        margin-bottom: 0;
                    }
                }
                .content-block-image{
                    flex: 1 1 100%;
                    min-height: 240px;
                    margin-left: 0;
                    margin-right: 0;
                    margin-bottom: 40px;
                    position: relative;
                    &::after{
                        content: ' ';
                        position: absolute;
                        z-index: -1;
                        width: 720px;
                        height: 384px;
                        right: -238px;
                        bottom: -105px; 
                        background: url('../img/solutions/bg-img-dot.png') center center no-repeat;
                        background-size: cover;
                    }
                }
            }
            .content-block.set-right{
                flex-flow: column-reverse wrap;
                margin-left: 0;
                margin-right: 0;
                .content-block-section{
                    flex: 1 1 100%;
                    margin-left: 0;
                    margin-right: 0;                       
                    .content-block-desc{
                        margin-bottom: 20px;
                    }
                    .button.btn.btn-blue{
                        margin-bottom: 0;
                    }
                }
                .content-block-image{    
                    &::after{
                        content: ' ';
                        position: absolute;
                        z-index: -1;
                        left: -238px;
                        bottom: -105px; 
                    }      
                }
            }
        }
    }  
}

@media screen and (max-width:768px){   

}

 /* - common override -*/
 h4{
     line-height: 1.5;
 }